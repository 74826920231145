<template>
    <div class="stock-item-owner-add">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.stockItemOwnerCreate')"
            :title="$t('messages.stockItemOwnerCreate')"
            @submitPressed="submitPressed"
        />
        <stock-item-owner-form :ownerObject="owner" :action="action" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import StockItemOwnerForm from './components/StockItemOwnerForm.vue'

export default {
    components: {
        TitleBar,
        StockItemOwnerForm
    },
    data() {
        return {
            action: null,
            owner: {}
        }
    },
    methods: {
        submitPressed() {
            this.action = 'add'
        }
    }
}
</script>
